<template>
  <power-bi code="eyJrIjoiMTNkNmMxYWItNGM5ZC00YjA0LWIzZWEtMTE4MzQ3OTNlNDczIiwidCI6ImM4NDJjMWQ5LWQzNjQtNDcxNi1iN2UzLWNhNDgxYTIzZDZhYyJ9" />
</template>

<script>
import PowerBi from '../reports/components/PowerBi.vue';

export default {
    components: {
        PowerBi,
    },
};
</script>
